// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-lekoarts-gatsby-theme-cara-templates-blog-tsx": () => import("./../../../src/@lekoarts/gatsby-theme-cara/templates/blog.tsx" /* webpackChunkName: "component---src-lekoarts-gatsby-theme-cara-templates-blog-tsx" */),
  "component---src-lekoarts-gatsby-theme-cara-templates-cara-tsx": () => import("./../../../src/@lekoarts/gatsby-theme-cara/templates/cara.tsx" /* webpackChunkName: "component---src-lekoarts-gatsby-theme-cara-templates-cara-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-mvp-development-company-tsx": () => import("./../../../src/pages/mvp-development-company.tsx" /* webpackChunkName: "component---src-pages-mvp-development-company-tsx" */),
  "component---src-pages-pricing-blackcherry-tsx": () => import("./../../../src/pages/pricing/blackcherry.tsx" /* webpackChunkName: "component---src-pages-pricing-blackcherry-tsx" */),
  "component---src-pages-pricing-caramelswirl-tsx": () => import("./../../../src/pages/pricing/caramelswirl.tsx" /* webpackChunkName: "component---src-pages-pricing-caramelswirl-tsx" */),
  "component---src-pages-pricing-coffee-tsx": () => import("./../../../src/pages/pricing/coffee.tsx" /* webpackChunkName: "component---src-pages-pricing-coffee-tsx" */),
  "component---src-pages-pricing-mintchip-tsx": () => import("./../../../src/pages/pricing/mintchip.tsx" /* webpackChunkName: "component---src-pages-pricing-mintchip-tsx" */),
  "component---src-pages-pricing-peppermint-tsx": () => import("./../../../src/pages/pricing/peppermint.tsx" /* webpackChunkName: "component---src-pages-pricing-peppermint-tsx" */),
  "component---src-pages-pricing-rockyroad-tsx": () => import("./../../../src/pages/pricing/rockyroad.tsx" /* webpackChunkName: "component---src-pages-pricing-rockyroad-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../../../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */)
}

